(() => {
  const sliders = {
    init() {
      this._eventsSlider();
      this._centeredSlider();
      this._newsSlider();
      this._pressSlider();
      this._quoteSlider();
      this._pictureSlider();
      this._pageheadSlider();
      this._tabsSlider();
    },

    _centeredSlider() {
      if (!document.querySelector('.js-centered-slider')) return;

      const elems = document.querySelectorAll('.js-centered-slider');

      [].forEach.call(elems, elem => {
        const slider = new Swiper(elem, {
          speed: 1000,
          slidesPerView: 'auto',
          centeredSlides: true,
          slideActiveClass: 'is-active',
          slideNextClass: 'is-next',
          slidePrevClass: 'is-prev',
          loop: true,
          loopedSlides: 4,
          navigation: {
            prevEl: '.js-prev',
            nextEl: '.js-next',
            disabledClass: 'is-disabled'
          },
          on: {
            init: function() {
              const slides = this.slides;

              [].forEach.call(slides, slide => {
                const width = slide.offsetWidth;
                const height = width / 1.4;
                slide.style.height = height + 'px';
              });
            },
            resize: function() {
              const slides = this.slides;

              [].forEach.call(slides, slide => {
                const width = slide.offsetWidth;
                const height = width / 1.4;
                slide.style.height = height + 'px';
              });

              setTimeout(() => {
                this.update();
              }, 300);
            }
          }
        });
      });
    },

    _eventsSlider() {
      if (!document.querySelector('#events-slider')) return;

      const slider = document.querySelector('#events-slider');
      const slides = slider.querySelectorAll('.swiper-slide');

      const eventsSlider = new Swiper('#events-slider', {
        speed: 1000,
        slidesPerView: 'auto',
        loop: (slides.length > 2) ? true : false,
        loopedSlides: 4,
        navigation: {
          prevEl: '#events-slider-prev',
          nextEl: '#events-slider-next',
          disabledClass: 'is-disabled'
        },
        pagination: {
          el: '#events-slider-pagination',
          type: 'fraction',
          bulletClass: 'bullet',
          bulletActiveClass: 'is-active',
          currentClass: 'is-current',
          totalClass: 'is-total',
          modifierClass: '',
          formatFractionCurrent: function(number) {
            if (number < 10) {
              return '0' + number;
            }
          },
          formatFractionTotal: function(number) {
            if (number < 10) {
              return '0' + number;
            }
          }
        },
        on: {
          init: function () {
            sliders._slideSameHeight(this);
          },
          resize: function() {
            sliders._slideSameHeight(this);
          }
        }
      });
    },

    _newsSlider() {
      if (!document.querySelector('#news-slider')) return;

      const newsSlider = new Swiper('#news-slider', {
        speed: 1000,
        slidesPerView: 'auto',
        loop: true,
        loopedSlides: 4,
        navigation: {
          prevEl: '#news-slider-prev',
          nextEl: '#news-slider-next',
          disabledClass: 'is-disabled'
        },
        pagination: {
          el: '#news-slider-pagination',
          type: 'fraction',
          bulletClass: 'bullet',
          bulletActiveClass: 'is-active',
          currentClass: 'is-current',
          totalClass: 'is-total',
          modifierClass: '',
          formatFractionCurrent: function(number) {
            if (number < 10) {
              return '0' + number;
            }
          },
          formatFractionTotal: function(number) {
            if (number < 10) {
              return '0' + number;
            }
          }
        },
        on: {
          init: function () {
            sliders._slideSameHeight(this);
          },
          resize: function() {
            sliders._slideSameHeight(this);
          }
        }
      });
    },

    _pressSlider() {
      if (!document.querySelector('#press-slider')) return;

      const pressSlider = new Swiper('#press-slider', {
        speed: 1000,
        slidesPerView: 'auto',
        loop: true,
        loopedSlides: 4,
        navigation: {
          prevEl: '.js-prev',
          nextEl: '.js-next',
          disabledClass: 'is-disabled'
        },
        on: {
          init: function() {
            const sliderWidth = this.el.offsetWidth;

            let slidesWidth = 0;

            [].forEach.call(this.slides, slide => {
              slidesWidth += slide.offsetWidth;
            });

            if (slidesWidth < sliderWidth) {
              this.params.centeredSlides = true;
              this.update();
            }
          }
        }
      });
    },

    _quoteSlider() {
      if ( !document.querySelector('#quote-slider') ) return;

      const el = document.querySelector('#quote-slider');

      let params = {
        speed: 1000,
        slidesPerView: 'auto',
        loop: true,
        loopedSlides: 4,
        navigation: {
          prevEl: '.js-prev',
          nextEl: '.js-next',
          disabledClass: 'is-disabled'
        }
      };

      if ( el.classList.contains('js-quote-pagination') ) {
        params.pagination = {
          el: '.slider-dots',
          type: 'bullets',
          bulletClass: 'slider-dots__item',
          bulletActiveClass: 'is-active',
          currentClass: 'is-current',
          modifierClass: '',
          clickable: true
        }
      }

      const slider = new Swiper(el, params);
    },

    _pictureSlider() {
      if ( !document.querySelector('#picture-slider') ) return;

      const interleaveOffset = 0.5;

      const slider = new Swiper('#picture-slider', {
        slidesPerView: 1,
        loop: true,
        loopedSlides: 4,
        speed: 1000,
        navigation: {
          prevEl: '.js-prev',
          nextEl: '.js-next',
          disabledClass: 'is-disabled'
        },
        on: {
          progress: function() {
            for (var i = 0; i < this.slides.length; i++) {
              var slideProgress = this.slides[i].progress;
              var innerOffset = this.width * interleaveOffset;
              var innerTranslate = slideProgress * innerOffset;
              this.slides[i].querySelector(".slide-inner").style.transform =
                "translate3d(" + innerTranslate + "px, 0, 0)";
            }
          },
          touchStart: function() {
            for (var i = 0; i < this.slides.length; i++) {
              this.slides[i].style.transition = "";
            }
          },
          setTransition: function(speed) {
            for (var i = 0; i < this.slides.length; i++) {
              this.slides[i].style.transition = speed + "ms";
              this.slides[i].querySelector(".slide-inner").style.transition =
                speed + "ms";
            }
          }
        }
      });
    },

    _pageheadSlider() {
      if ( !document.querySelector('#pagehead-slider') ) return;

      const slider = new Swiper('#pagehead-slider', {
        speed: 1000,
        slidesPerView: 'auto',
        loop: true,
        loopedSlides: 4,
        navigation: {
          prevEl: '.js-prev',
          nextEl: '.js-next',
          disabledClass: 'is-disabled'
        },
      });
    },

    _tabsSlider() {
      if ( !document.querySelector('.js-tab-slider-pic') ) return;

      const elems = document.querySelectorAll('.js-tab-slider-pic');

      [].forEach.call(elems, elem => {
        const slider = new Swiper(elem, {
          speed: 1000,
          slidesPerView: 'auto',
          effect: 'fade',
          navigation: {
            prevEl: '.js-prev',
            nextEl: '.js-next',
            disabledClass: 'is-disabled'
          },
        });
      });
    },

    _slideSameHeight(slider) {
      const wrapper = slider.wrapperEl;

      [].forEach.call(slider.slides, slide => {
        slide.style.height = "";
      });

      setTimeout(() => {
        [].forEach.call(slider.slides, slide => {
          slide.style.height = wrapper.clientHeight + "px";
        });
      }, 300);
    }
  };

  sliders.init();
})();
