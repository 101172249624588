(() => {
  const herotimer = {
    init() {
      if ( !document.querySelector('#loader') ) return;

      const check = setInterval(() => {
        if ( loader.classList.contains('is-done') ) {

          clearInterval(check);

          setTimeout(() => {
            this._timer('#hero-timer');
          }, 1000);
        }
      }, 100);
    },

    _timer(elemClass) {
      if (!document.querySelector(elemClass)) return;

      const elem = document.querySelector(elemClass);
      const elemDate = elem.dataset.date;

      const numbers = Array.from( elem.querySelectorAll('.odometer') );

      const tick = setInterval(() => {
        const obj = this._getLeftTime(elemDate);

        obj.map( (value,idx) => {
          numbers[idx].innerHTML = value;
        });
      }, 1000);
    },

    _getLeftTime(elemDate) {
      const date = Date.parse(elemDate);
      const now = Date.now();

      if (now >= date) return '';

      let diff = date - now;

      const oneDay = 1000 * 60 * 60 * 24;
      const oneHour = 1000 * 60 * 60;
      const oneMin = 1000 * 60;

      const days = Math.floor(diff / oneDay);
      const daysMs = days * oneDay;

      diff -= daysMs;

      const hours = Math.floor((diff / oneHour));
      const hoursMs = hours * oneHour;

      diff -= hoursMs;

      const mins = Math.floor((diff / oneMin));
      const minsMs = mins * oneMin;

      diff -= minsMs;

      const sec = Math.floor(diff / 1000);

      // const formatDays = (days < 10) ? `0${days}` : days;
      // const formatHours = (hours < 10) ? `0${hours}` : hours;
      // const formatMins = (mins < 10) ? `0${mins}` : mins;
      // const formatSec = (sec < 10) ? `0${sec}` : sec;

      const obj = [ days, hours, mins, sec ];

      return obj;
    }
  };

  document.addEventListener('DOMContentLoaded', () => {
    herotimer.init();
  });
})();
