(() => {
  const gallery = {
    init() {
      this._masonry('#gallery-grid');

      window.addEventListener('resize', () => {
        setTimeout(() => {
          this._masonry('#gallery-grid');
        }, 100);
      });

      this._filter();
    },

    _masonry(containerID) {
      if (!document.querySelector(containerID)) return;

      const winW = window.innerWidth;

      const container = document.querySelector(containerID);
      let cols = (container.hasAttribute('data-cols')) ? parseInt(container.dataset.cols) : 2;

      const items = container.querySelectorAll('.js-item.is-active');

      [].forEach.call(items, (item, index) => {
        item.style.marginTop = '';
      });

      [].forEach.call(items, (item, index) => {
          const itemH = item.offsetHeight;
          const picture = item.querySelector('.js-picture');
          const picH = picture.offsetHeight;
          let offset = 20;

          if ( winW < 768 ) {
            offset = 10;
          }

          const diff = itemH - picH - offset;

          if ((index + cols) < items.length) {
            items[index + cols].style.marginTop = '-' + diff + 'px';
          }
      });
    },

    _filter() {
      const buttons = document.querySelectorAll('[data-filter-button]');

      [].forEach.call(buttons, button => {
        button.addEventListener('click', e => {

          const btn = e.currentTarget;

          if ( btn.classList.contains('is-current') ) {

            btn.classList.remove('is-current');

          } else {

            [].forEach.call(buttons, button => button.classList.remove('is-current'));
            btn.classList.add('is-current');
          }

          const filterValue = btn.dataset.filterButton;

          const items = document.querySelectorAll('.js-filter');

          if ( !btn.classList.contains('is-current') ) {
            [].forEach.call(items, elem => {
              elem.classList.add('is-hide');
              elem.classList.remove('is-active');
            });

            setTimeout(() => {
              [].forEach.call(items, elem => {
                elem.classList.remove('is-hide');
                elem.classList.add('is-active');
              });

              this._masonry('#gallery-grid');
            }, 1);

            return;
          }

          [].forEach.call(items, elem => {
            elem.classList.add('is-hide');
            elem.classList.remove('is-active');
            elem.style.marginTop = 0;
          });

          setTimeout(() => {
            [].forEach.call(items, elem => {
              if ( elem.hasAttribute('data-filter') ) {
                const elemFilterValue = elem.dataset.filter;

                if ( elemFilterValue == filterValue ) {
                  elem.classList.remove('is-hide');
                  elem.classList.add('is-active');
                }
              }
            });

            this._masonry('#gallery-grid');
          }, 1);

        });
      });
    }
  };

  window.addEventListener('load', () => {
    gallery.init();
  });
})();
