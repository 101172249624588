(() => {
  const app = {
    init() {
      svg4everybody();

      zenscroll.setup(null, 0);

      this._mainmenu();

      window.odometerOptions = {
        auto: false,
        duration: 2000
      };

      const clickout = new clickOut.default({
        after: elements => {
          if (elements) {
            [].forEach.call(elements, elem => {
              // Для полумодалки - включаем скролл страницы после закрытия
              if (elem.hasAttribute('data-group')) {
                if (elem.dataset.group === 'yacht-spec') {
                  document.querySelector('#section-yacht-spec').classList.remove('is-active');
                  document.documentElement.classList.remove('is-no-scroll');
                }
              }
            });
          }
        }
      });

      // Gallery
      if (document.querySelector('.js-baguetteBox-gallery')) {
        baguetteBox.run('.js-baguetteBox-gallery', {
          overlayBackgroundColor: 'rgba(255,255,255,1)'
        });
      }
    },

    _mainmenu() {
      if ( !document.querySelector('#main-menu') ) return;

      const container = document.querySelector('#main-menu');

      const psSubs = Array.from( document.querySelectorAll('.js-main-menu-ps') );
      let psItems = [];

      psSubs.forEach(el => {
        const ps = new PerfectScrollbar(el);
        psItems.push(ps);
      });

      container.addEventListener('click', e => {
        if ( !e.target.closest('.is-subs') ) return;
        e.preventDefault();

        e.target.closest('.is-subs').classList.add('is-active');
        container.classList.add('is-subs-active');

        psItems.forEach(item => {
          item.update();
        });
      });

      container.addEventListener('click', e => {
        if ( !e.target.classList.contains('js-subs-back') ) return;

        e.target.closest('.is-subs').classList.remove('is-active');
        container.classList.remove('is-subs-active');

      });
    }
  };

  app.init();
})();
