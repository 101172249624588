(() => {
  const app = {
    init() {
      this.el = document.querySelector('#cookie-agree');
      this.btn = document.querySelector('#cookie-agree-btn');

      this.btn.addEventListener('click', e => {
        app._showAgreement( false );
        app._accept();
      });

      app._check();
    },

    _check() {
      let check = sessionStorage.getItem('cookie-agree');

      if ( check === null ) {
        check = 0;
      } else {
        check = parseInt( sessionStorage.getItem('cookie-agree') );
      }

      if ( !check ) {
        app._showAgreement( true );
      }
    },

    _accept() {
      sessionStorage.setItem('cookie-agree', 1);
    },

    _showAgreement( status ) {
      if ( status ) {
        this.el.classList.add('is-active');
      } else {
        this.el.classList.remove('is-active');
      }
    }
  };

  window.onload = () => {
    if ( document.querySelector('.js-last-hero-animation') ) {

      const check = setInterval(() => {
        if ( window.heroAnimationComplete ) {
          clearInterval(check);
          app.init();
        }
      }, 1200);

    } else {

      const check = setInterval(() => {
        if (!Pace.running) {
          clearInterval(check);
          app.init();
        }
      }, 3000);

    }
  };
})();
