(() => {
  const cursor = {
    init() {

      this.elem = document.querySelector('#cursor');
      this.inner = this.elem.querySelector('.cursor-ui__inner');
      this.outer = this.elem.querySelector('.cursor-ui__outer');

      window.addEventListener('resize', e => {
        const touch = this._is_touch();

        if ( !touch ) {
          this.elem.style.opacity = 1;
        }
      });

      const touch = this._is_touch();

      if ( !touch ) {
        this.elem.style.opacity = 1;
      }

      this.clientX = 0;
      this.clientY = 0;

      // const bigCircle = (text, setup) => {
      //   console.log(text);

      //   if ( setup ) {
      //     this.inner.innerHTML = '<span>' + text + '</span>';

      //     setTimeout(() => {
      //       this.elem.classList.add('is-hover-more');
      //     }, 0);
      //   }

      //   if ( !setup ) {
      //     this.inner.innerHTML = text;
      //     this.elem.classList.remove('is-hover-more');
      //   }
      // }

      document.addEventListener('mousemove', e => {
        this.clientX = e.clientX;
        this.clientY = e.clientY;

        if ( e.target.closest('a, button, .js-cursor-link') ) {
          const elem = e.target.closest('a, button, .js-cursor-link');

          if ( elem.classList.contains('is-disabled') ) {
            this.elem.classList.add('is-disabled');
          }
        } else {
          this.elem.classList.remove('is-disabled');
        }
      });

      document.addEventListener('mouseover', e => {
        if ( e.target.closest('a, button, .js-cursor-link') ) {
          this.elem.classList.add('is-hover');
        } else {
          this.elem.classList.remove('is-hover');
        }

        if ( e.target.closest('[data-cursor-darken]') ) {
          this.elem.classList.add('is-darken');
        } else {
          this.elem.classList.remove('is-darken');
        }

        if ( e.target.closest('.js-cursor-link-text' ) ) {
          const el = e.target.closest('.js-cursor-link-text');

          const text = ( el.hasAttribute('data-text') ) ? el.dataset.text : '';

          if ( text ) {
            this._bigCircle(text, true);
          }
          return;
        } else {
          this._bigCircle('', false);
        }

        // if ( e.target.closest('.js-cursor-link-more, .js-cursor-link-zoom, .js-cursor-link-prev, .js-cursor-link-next, .js-cursor-link-read, .js-cursor-link-rent') ) {

        //   if ( e.target.closest('.js-cursor-link-more') ) {
        //     this._bigCircle('Подробнее', true);
        //   }

        //   if ( e.target.closest('.js-cursor-link-zoom') ) {
        //     this._bigCircle('Увеличить', true);
        //   }

        //   if ( e.target.closest('.js-cursor-link-prev') ) {
        //     this._bigCircle('Предыдущий', true);
        //   }

        //   if ( e.target.closest('.js-cursor-link-next') ) {
        //     this._bigCircle('Следующий', true);
        //   }

        //   if ( e.target.closest('.js-cursor-link-read') ) {
        //     this._bigCircle('Читать', true);
        //   }

        //   if ( e.target.closest('.js-cursor-link-rent') ) {
        //     this._bigCircle('Арендовать', true);
        //   }
        //   return;
        // } else {
        //   this._bigCircle('', false);
        //   return;
        // }
      });

      const render = () => {
        gsap.set(this.elem, {x: this.clientX, y: this.clientY});
        requestAnimationFrame(render);
      };

      requestAnimationFrame(render);
    },

    _bigCircle( text, setup ) {

      if ( setup ) {
        this.inner.innerHTML = '<span>' + text + '</span>';

        setTimeout(() => {
          this.elem.classList.add('is-hover-more');
        }, 0);
      }

      if ( !setup ) {
        this.inner.innerHTML = text;
        this.elem.classList.remove('is-hover-more');
      }
    },

    _is_touch() {
      if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
        return true;
      }

      return false;
    }
  };

  cursor.init();
})();
