(() => {
  const gmap = {
    PATH: '/assets/data/globus.json',
    MAP: {},
    MAPEL: {},
    MAPWRAP: {},

    init: () => {
      if ( !document.querySelector('#apimap') ) return;

      gmap.MAPEL = document.querySelector('#apimap');

      gmap.MAPWRAP = gmap.MAPEL.parentElement;

      gmap.loadMap();
    },

    loadMap: () => {
      const script = document.createElement('script');
      script.src = 'https://maps.googleapis.com/maps/api/js?key=&libraries=geometry&callback=initMap';
      script.defer = true;
      script.async = true;
      document.head.appendChild(script);

      gmap.drawMap( 'apimap' );
    },

    drawMap: ( id ) => {
      const el = document.getElementById(id);

      window.initMap = function() {
        gmap.MAP = new google.maps.Map(document.getElementById(id), {
          center: {lat: -34.397, lng: 150.644},
          zoom: 8,
          zoomControl: false,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          styles:[{featureType:"water",elementType:"geometry",stylers:[{color:"#e9e9e9"},{lightness:17}]},{featureType:"landscape",elementType:"geometry",stylers:[{color:"#f5f5f5"},{lightness:20}]},{featureType:"road.highway",elementType:"geometry.fill",stylers:[{color:"#ffffff"},{lightness:17}]},{featureType:"road.highway",elementType:"geometry.stroke",stylers:[{color:"#ffffff"},{lightness:29},{weight:0.2}]},{featureType:"road.arterial",elementType:"geometry",stylers:[{color:"#ffffff"},{lightness:18}]},{featureType:"road.local",elementType:"geometry",stylers:[{color:"#ffffff"},{lightness:16}]},{featureType:"poi",elementType:"geometry",stylers:[{color:"#f5f5f5"},{lightness:21}]},{featureType:"poi.park",elementType:"geometry",stylers:[{color:"#dedede"},{lightness:21}]},{elementType:"labels.text.stroke",stylers:[{visibility:"on"},{color:"#ffffff"},{lightness:16}]},{elementType:"labels.text.fill",stylers:[{saturation:36},{color:"#333333"},{lightness:40}]},{elementType:"labels.icon",stylers:[{visibility:"off"}]},{featureType:"transit",elementType:"geometry",stylers:[{color:"#f2f2f2"},{lightness:19}]},{featureType:"administrative",elementType:"geometry.fill",stylers:[{color:"#fefefe"},{lightness:20}]},{featureType:"administrative",elementType:"geometry.stroke",stylers:[{color:"#fefefe"},{lightness:17},{weight:1.2}]}]
        });

        gmap.getData( parseInt(el.dataset.geoId) );

        gmap.buttons( id );
      };
    },

    getData: ( key ) => {
      fetch(gmap.PATH)
      .then(response => response.json())
      .then(json => {
        if (json && json.data) {
          // console.log(json);

          const data = json.data[key];

          const latlngbounds = new google.maps.LatLngBounds();

          const markerImage = {
            url: '/assets/icons/map-marker.svg',
            scaledSize: new google.maps.Size(20, 20),
            size: new google.maps.Size(20, 20),
            anchor: new google.maps.Point(10, 10)
          };

          data.forEach((item, index) => {
            const location = item.location;

            const marker = new google.maps.Marker({
              position: location,
              map: gmap.MAP,
              icon: markerImage,
              title: item.data.city
            });

            const info = new google.maps.InfoWindow({
              content: '<div id="popup-' + index + '" class="map-box-popup__txt">' + item.data.city + '</div>'
            });

            google.maps.event.addListener(info, 'domready', () => {
              const elem = gmap.MAPEL.querySelector('#popup-' + index);
              const wrapper = elem.closest('.gm-style-iw-t');
              wrapper.classList.add('map-box-popup');
            });

            info.open(gmap.MAP, marker);

            latlngbounds.extend( location );
          });

          gmap.MAP.setCenter( latlngbounds.getCenter() );
          gmap.MAP.fitBounds( latlngbounds );

          const path = data.map(item => {
            return item.location;
          });

          const lineSymbol = {
            path: "M 0,-1 0,1",
            strokeOpacity: 1,
            scale: 1
          };

          const routePath = new google.maps.Polyline({
            path: path,
            geodesic: true,
            strokeColor: '#1D8EFF',
            strokeOpacity: 0,
            strokeWeight: 1,
            icons: [
              {
                icon: lineSymbol,
                offset: "0",
                repeat: "5px"
              }
            ],
          });

          routePath.setMap(gmap.MAP);

          // Distance
          const startPath = path[ path.length - 2 ];
          const endPath = path[ path.length - 1 ];

          const m = google.maps.geometry.spherical.computeDistanceBetween(
            new google.maps.LatLng( startPath ),
            new google.maps.LatLng( endPath )
          );

          const Nm = Math.ceil(m / 1852);

          const length = google.maps.geometry.spherical.computeLength( routePath.getPath() );
          const heading = google.maps.geometry.spherical.computeHeading(
            new google.maps.LatLng( startPath ),
            new google.maps.LatLng( endPath )
          );

          const offset = google.maps.geometry.spherical.computeOffset(
            new google.maps.LatLng( startPath ),
            Math.floor(m / 2),
            heading
          );

          const markerCenterArrow = {
            path: 'm11.04 2e-3c-0.33 0-0.63 0.201-0.74 0.505l-8.033 20.37c-0.117 0.33 0 0.66 0.217 0.89 0.253 0.23 0.614 0.28 0.912 0.13l7.624-4.16 7.65 4.17c0.27 0.15 0.63 0.12 0.91-0.12 0.19-0.23 0.27-0.56 0.14-0.86l-7.94-20.42c-0.14-0.305-0.43-0.506-0.74-0.506z',
            fillColor: '#1D8BFA',
            fillOpacity: 1,
            strokeColor: '#1D8BFA',
            scale: 1,
            anchor: new google.maps.Point(12,12),
            rotation: ( Math.abs(heading) <= 111 ) ? (heading - 10) : heading
          };

          const markerCenter = new google.maps.Marker({
            position: offset,
            map: gmap.MAP,
            icon: markerCenterArrow,
          });

          const infoMarkerCenter = new google.maps.InfoWindow({
            content: '<div style="map-box-popup-length__txt" id="popup-length">' + Nm + '<br/>морских миль</div>'
          });

          google.maps.event.addListener(infoMarkerCenter, 'domready', () => {
            const elem = gmap.MAPEL.querySelector('#popup-length');
            const wrapper = elem.closest('.gm-style-iw-t');
            wrapper.classList.add('map-box-popup-length');
          });

          infoMarkerCenter.open(gmap.MAP, markerCenter);
        }
      });
    },

    buttons: ( id ) => {
      const el = document.getElementById(id);
      const elParent = el.parentElement;

      const zoomIn = elParent.querySelector('.js-zoom-in');
      const zoomOut = elParent.querySelector('.js-zoom-out');

      zoomIn.addEventListener('click', e => {
        let zoom = gmap.MAP.getZoom();
        zoom += 1;

        if ( zoom >= 20 ) {
          zoom = 20;
        }

        gmap.MAP.setZoom( zoom );
      });

      zoomOut.addEventListener('click', e => {
        let zoom = gmap.MAP.getZoom();
        zoom -= 1;

        if ( zoom < 1 ) {
          zoom = 1;
        }

        gmap.MAP.setZoom( zoom );
      });
    }
  };

  gmap.init();
})();
