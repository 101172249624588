(() => {
  const globus = {
    PATH: '/assets/data/globus.json',

    init() {
      const globusWrap = document.querySelector('.js-globus');
      if (globusWrap) {
        globus.getData(globusWrap);
      }
    },

    getData: (wrap) => {
      fetch(globus.PATH)
        .then(response => response.json())
        .then(json => {
          if (json && json.data) {
            // console.log(json);
            globus.renderGlobus(json, wrap);
          }
        });
    },

    renderGlobus: (data, wrap) => {
      if (!document.querySelector('#maps-slides') || !data) return;

      let cur_route = 0;
      let myearth = new Earth(wrap, {
        location: { lat: 16, lng: 16 },
        light: 'none',
        mapLandColor : '#fff',
        mapSeaColor : '#DEE1EC',
        mapBorderColor : '#ced2de',
        mapBorderWidth : 0.2,
        quality: ( window.innerWidth < 1000 ) ? 3 : 4
      });

      myearth.addEventListener("ready", () => {
        const _data = data.data;
        const _countries = data.countries;

        let markers = [];
        let countries = [];

        const restore = (cb) => {
          if (markers) {
            for (let el in markers) {
              if (el === cur_route) continue;

              markers[el].forEach((item, i) => {
                item.animate('scale', 0.01, { complete: () => { item.visible = false; } } );
              });
            }
          }

          if (countries) {
            for (let el in countries) {
              if (el === cur_route) continue;

              countries[el].forEach((item, i) => {
                item.animate('scale', 0.01, { complete: () => { item.visible = false; } } );
              });
            }
          }

          if (cb) cb();
        };

        const buildRoute = () => {
          const route = _data[cur_route];
          const country = _countries[cur_route];

          markers = {};
          countries = {};

          let styles = '';

          country.forEach((loc, i) => {
            if (loc && loc.location) {

              if ( !countries[cur_route] || (countries[cur_route].length !== route.length) ) {

                let marker = myearth.addOverlay( {
                  content: `<div class="globus__country"><span>${loc.countryName}</span></div>`,
                  location: loc.location,
                  visible: true,
                  elementScale: 1,
                  depthScale: 0.5
                } );

                setTimeout(() => {
                  if (countries[cur_route]) {
                    countries[cur_route].push(marker);
                  } else {
                    countries = {
                      ...countries,
                      [cur_route]: [marker],
                    };
                  }
                }, 0 * i);

              } else {

                countries[cur_route].forEach((el, ind) => {
                  setTimeout(() => {
                    el.visible = true;
                  }, 0 * ind);
                });

              }

            }
          });

          route.forEach((loc, i) => {

            if (i === 0) {
              let _loc;

              if (route[i + 1]) {
                _loc = {
                  lat: ((route[i + 1].location.lat + loc.location.lat) / 2),
                  lng: ((route[i + 1].location.lng + loc.location.lng) / 2) + 23,
                };
              } else {
                let tmp = {
                  lat: loc.location.lat,
                  lng: loc.location.lng + 30,
                };
                _loc = tmp;
              }

              myearth.goTo( _loc, { duration: 100, relativeDuration: 100 } );
            }

            styles += `${styles === '' ? '' : ' '}#${loc.countryCode}${i !== route.length - 1 ? ',' : ''}`;

            if ( !markers[cur_route] || (markers[cur_route].length !== route.length) ) {

              let marker;

              if (loc.data.pic) {
                marker = myearth.addOverlay( {
                  content: `<div class="globus__pin bubbled"><span></span></div>  <div class="globus__picWrap"><div class="globus__picWrap_city">${loc.data.city}</div></div>`,
                  location: loc.location,
                  visible: true,
                  elementScale: 1,
                  depthScale: 0.5
                } );
              } else {
                marker = myearth.addOverlay( {
                  content: `<div class="globus__pin"><span></span></div> <div class="globus__baloon">${loc.data.city}</div>`,
                  location: loc.location,
                  visible: true,
                  elementScale: 1,
                  depthScale: 0.5
                } );
              }

              setTimeout(() => {
                if (markers[cur_route]) {
                  markers[cur_route].push(marker);
                } else {
                  markers = {
                    ...markers,
                    [cur_route]: [marker],
                  };
                }
              }, 0 * i);

            } else {

              markers[cur_route].forEach((el, ind) => {
                setTimeout(() => {
                  el.visible = true;
                }, 0 * ind);
              });

            }
          });

          myearth.mapStyles = `${styles} { fill: #111c3e; } \
          `;
          myearth.redrawMap();
        };

        const changeMapData = (index) => {
          setTimeout(() => {
            cur_route = index;

            if (!isNaN(cur_route)) {
              restore();
              buildRoute();
            }
          }, 0);
        };

        window.changeMapData = changeMapData;
      });
    },
  };

  let _init = false;

  const initHandler = () => {
    const globus_wrap = document.querySelector('.section-maps');
    const top = document.documentElement.scrollTop || document.body.scrollTop;

    // console.log(top, globus_wrap.offsetTop);
    if (!_init) {
      if (top >= globus_wrap.offsetTop - 150) {
        _init = true;
        globus.init();
      }
    } else {
      document.removeEventListener('scroll', initHandler);
    }
  };

  if ( !document.querySelector('.js-globus') ) return;

  document.addEventListener('scroll', initHandler);
})();
