(() => {
  const animation = {
    opts: {
      fadeUp: {
        from: {opacity: 0, y: 50},
        to: {opacity: 1, y: 0, delay: 0, duration: 1, ease: 'circ.out'},
      },
      fadeLeft: {
        from: {opacity: 0, x: 50},
        to: {opacity: 1, x: 0, delay: 0, duration: 1, ease: 'circ.out'}
      },
      fadeRight: {
        from: {opacity: 0, x: -100},
        to: {opacity: 1, x: 0, delay: 0, duration: 1, ease: 'circ.out'}
      },
      fade: {
        from: {opacity: 0},
        to: {opacity: 1, delay: 0, duration: 1, ease: 'circ.out'},
      },
      slideDown: {
        from: {y: '-105%'},
        to: {y: 0, duration: 2.5, ease: 'power2.inOut'},
      },
      rollDown: {
        from: {scaleY: 1},
        to: {scaleY: 0, duration: 1.5, ease: Power2.easeInOut}
      }
    },
    init() {
      const check = setInterval(() => {
        if (!Pace.running) {
          clearInterval(check);

          if (window.innerWidth > 1200) {
            window.heroAnimationComplete = false;

            this._splitText();
            this._fading();
            this._fadeLeftSlider();
            this._logos();
            this._odometer();
          }
        }
      }, 5);
    },

    _splitText() {
      const elems = document.querySelectorAll('.js-split-text');

      [].forEach.call(elems, elem => {
        const string = elem.innerText;
        const splitString = string.split('');

        const formatSplitString = splitString.map(char => {
          if (char == ' ') {
            return '</div><div class="char-space">'+ char +'</div><div class="word">';
          }
          return '<div class="char">'+ char +'</div>';
        });

        const newString = '<div class="word">' + formatSplitString.join('') + '</div>';
        elem.innerHTML = newString;
      });

      this._splitTextRotation();
      this._splitTextFadeUp();
    },

    _splitTextRotation() {
      const elems = document.querySelectorAll('.js-split-text-rotation');

      const controller = new ScrollMagic.Controller();

      [].forEach.call(elems, elem => {
        const scene = new ScrollMagic.Scene({
          triggerElement: elem,
          triggerHook: 0.85,
          reverse: false
        });

        let delay = elem.dataset.delay;

        if (!delay) {
          delay = 0;
        }

        const chars = elem.querySelectorAll('.char');

        scene.setTween(gsap.set(chars, {opacity: 0, rotationX: 100}));
        scene.setTween(gsap.to(chars, {opacity: 1, rotationX: 0, delay: parseFloat(delay), duration: 0.5, ease: 'circ.out', stagger: 0.05}));

        scene.addTo(controller);
      });
    },

    _splitTextFadeUp() {
      const elems = document.querySelectorAll('.js-split-text-fadeUp');

      const controller = new ScrollMagic.Controller();

      [].forEach.call(elems, elem => {
        const scene = new ScrollMagic.Scene({
          triggerElement: elem,
          triggerHook: 0.85,
          reverse: false
        });

        let delay = elem.dataset.delay;

        if (!delay) {
          delay = 0;
        }

        const chars = elem.querySelectorAll('.char');

        scene.setTween( gsap.set(chars, {opacity: 0, y: '50%'}) );
        scene.setTween( gsap.to(chars, {
          opacity: 1,
          y: 0,
          delay: parseFloat(delay),
          duration: 1,
          ease: Power1.easeInOut,
          stagger: 0.05}) );

        scene.addTo(controller);
      });
    },

    _fading() {
      const controller = new ScrollMagic.Controller();

      const types = ['fade', 'fadeUp', 'fadeLeft', 'fadeRight', 'slideDown', 'rollDown'];

      types.forEach(type => {
        const elems = document.querySelectorAll('.js-scroll-' + type);

        if (!elems) return;

        [].forEach.call(elems, elem => {
          let delay = elem.dataset.delay;

          if (!delay) {
            delay = 0;
          }

          this.opts[type].to.delay = delay;

          this.opts[type].to.onCompleteParams = [elem];

          this.opts[type].to.onComplete = function() {
            if ( this.targets()[0].classList.contains('js-last-hero-animation') ) {
              window.heroAnimationComplete = true;
            }
          };

          const scene = new ScrollMagic.Scene({
            triggerElement: elem,
            triggerHook: 1,
            reverse: false
          })
          .setTween( gsap.set(elem, this.opts[type].from) )
          .setTween( gsap.to(elem, this.opts[type].to) )
          .addTo( controller );

          this.opts[type].to.delay = 0;
        });
      });
    },

    _fadeLeftSlider() {
      const controller = new ScrollMagic.Controller();
      const blocks = document.querySelectorAll('.js-fade-left-slider');

      [].forEach.call(blocks, block => {
        const elems = block.querySelectorAll('.js-item');

        let delay = 0.25;

        [].forEach.call(elems, elem => {
          delay += 0.25;

          const scene = new ScrollMagic.Scene({
            triggerElement: elem,
            triggerHook: 1,
            reverse: false
          })
          .setTween(gsap.set(elem, {opacity: 0, x: 200}))
          .setTween(gsap.from(elem, {opacity: 0, x: 200}))
          .setTween(gsap.to(elem, {opacity: 1, x: 0, delay: delay, duration: 1.5, ease: 'power1.out'}))
          .addTo(controller);
        });
      });
    },

    _logos() {
      if ( !document.querySelector('#logos') ) return;

      const container = document.querySelector('#logos');
      const elems = container.querySelectorAll('.js-item');

      [].forEach.call(elems, elem => {
        const interval = parseInt(elem.dataset.interval);

        setTimeout(() => {
          const int = setInterval(() => {
            if ( elem.classList.contains('is-switch') ) {
              elem.classList.remove('is-switch');
            } else {
              elem.classList.add('is-switch');
            }
          }, (4000 + interval));
        }, interval);

      });
    },

    _odometer() {
      if ( !document.querySelector('.odometer-text') ) return;

      const controller = new ScrollMagic.Controller();

      const scene = new ScrollMagic.Scene({
        triggerElement: '.include-box',
        triggerHook: 1,
        reverse: false
      })
      .addTo(controller);

      scene.on('start', function(event) {
        const el = document.querySelector('.odometer-text');

        const od = new Odometer({
          el: el,
          value: 0,
          // format: 'd'
        });

        // console.log( el.getAttribute('data-number') );

        el.innerText = el.getAttribute('data-number');
      });
    }
  };

  animation.init();
})();
