(() => {
  const mapsSlider = {
    init() {
      if (!document.querySelector('#maps-slides')) return;

      this._setup();

      this.slides[this.currentIndex].classList.add('is-active');
      this.pics[this.currentIndex].classList.add('is-active');

      const check = setInterval(() => {
        if (window.changeMapData) {
          clearInterval(check);
          window.changeMapData(this.currentIndex);
        }
      }, 300);

      this._statusNavButtons();

      this.container.addEventListener('click', e => {
        if (!e.target.closest('.js-btn')) return;

        const target = e.target.closest('.js-btn');
        const way = target.dataset.way;

        // to next
        if ( way === 'next' ) {
          this.nextIndex = this.currentIndex + 1;
        }

        // to prev
        if ( way === 'prev' ) {
          this.nextIndex = this.currentIndex - 1;
        }

        if ( this.nextIndex == this.length ) {
          this.nextIndex = 0;
        }

        if ( this.nextIndex < 0 ) {
          this.nextIndex = this.length - 1;
        }

        // if ( this.nextIndex == this.slides.length || this.nextIndex < 0 ) {
        //   return;
        // }

        const winW = window.innerWidth;

        this._statusNavButtons();

        if (winW > 1200) {
          this._animation(way);
        } else {
          this._static(way);
        }
      });
    },

    _setup() {
      this.container = document.querySelector('#maps-slides');
      this.slides = this.container.querySelectorAll('.js-maps-slides-item');
      this.pics = this.container.querySelectorAll('.js-maps-slides-pic-item');
      this.currentIndex = 0;
      this.nextIndex = 0;
      this.length = this.slides.length;
    },

    _statusNavButtons() {
      const prevBtn = this.container.querySelector('.js-btn[data-way="prev"]');
      const nextBtn = this.container.querySelector('.js-btn[data-way="next"]');


      // if ( this.currentIndex == 0 ) {
      //   prevBtn.classList.add('is-disabled');
      // }

      // if (this.nextIndex == this.slides.length - 1) {
      //   nextBtn.classList.add('is-disabled');
      // } else {
      //   nextBtn.classList.remove('is-disabled');
      // }

      // if (this.nextIndex == 0) {
      //   prevBtn.classList.add('is-disabled');
      // } else {
      //   prevBtn.classList.remove('is-disabled');
      // }
    },

    _static(way) {
      this.slides[this.currentIndex].classList.remove('is-active');
      this.slides[this.nextIndex].classList.add('is-active');

      this.pics[this.currentIndex].classList.remove('is-active');
      this.pics[this.nextIndex].classList.add('is-active');

      if (way === 'next') {
        this.currentIndex += 1;
      }
      if (way === 'prev') {
        this.currentIndex -= 1;
      }

      if ( this.currentIndex == this.length ) {
        this.currentIndex = 0;
      }

      if ( this.currentIndex < 0 ) {
        this.currentIndex = this.length - 1;
      }

      window.changeMapData(this.currentIndex);
    },

    _animation(way) {
      // Next Slide Setup
      const timelineNext = gsap.timeline({
        delay: 1
      });

      const elemsNext = this.slides[this.nextIndex].querySelectorAll('.js-animate');

      [].forEach.call(elemsNext, elem => {
        gsap.set(elem, {y: 50, opacity: 0});
      });

      [].forEach.call(this.pics, pic => {
        gsap.set(pic, {x: 0, 'z-index': 0});
      });

      gsap.set(this.pics[this.currentIndex], {x: 0, 'z-index': 1});
      gsap.set(this.pics[this.nextIndex], {x: '-100%', 'z-index': 2});

      [].forEach.call(elemsNext, elem => {
        timelineNext.to(elem, {y: 0, opacity: 1}, "<0.15");
      });

      // Current Slide Setup
      const elems = this.slides[this.currentIndex].querySelectorAll('.js-animate');

      const timeline = gsap.timeline({
        ease: "elastic",
        onComplete: () => {
          this.slides[this.currentIndex].classList.remove('is-active');
          this.slides[this.nextIndex].classList.add('is-active');

          this.pics[this.currentIndex].classList.remove('is-active');

          gsap.to(this.pics[this.nextIndex], {
            x: 0,
            ease: 'power4.out',
            duration: 1.5,
            onComplete: () => {
              this.pics[this.nextIndex].classList.add('is-active');
            }
          });

          if (way === 'next') {
            this.currentIndex += 1;
          }
          if (way === 'prev') {
            this.currentIndex -= 1;
          }

          if ( this.currentIndex == this.length ) {
            this.currentIndex = 0;
          }

          if ( this.currentIndex < 0 ) {
            this.currentIndex = this.length - 1;
          }

          window.changeMapData(this.currentIndex);

          timelineNext.play();
        }
      });

      gsap.set(this.pics[this.nextIndex], {x: '-100%'});

      [].forEach.call(elems, elem => {
        timeline.to(elem, {y: -50, opacity: 0}, "<0.15");
      });

      timeline.to(this.slides[this.currentIndex], {opacity: 0}, "<0.15");

      timeline.play();
    }
  };

  mapsSlider.init();
})();
