(() => {
  const modals = {
    init() {
      MicroModal.init({
        openTrigger: 'data-modal-open',
        closeTrigger: 'data-modal-close',
        onShow: modal => {
          this._modalClose(modal);
        }
      });
    },

    _modalClose(modal) {
      modal.addEventListener('click', e => {
        if ( !e.target.closest('[data-modal-close-btn]') ) return;
        MicroModal.close(modal.id);
      });
    }
  };

  modals.init();
})();
