(() => {
  const instapics = {
    init() {
      if (!document.querySelector('.js-instagram-pictures')) return;

      this._setup();
      this._moving();
    },

    _setup() {
      this.container = document.querySelector('.js-instagram-pictures');
      this.rail = this.container.querySelector('.js-rails');
      this.rails = this.container.querySelectorAll('.js-rail');
      this.items = this.rail.querySelectorAll('.js-item');

      this.containerH = this.container.offsetHeight;
      this.railH = this.rail.offsetHeight;
      this.diff = Math.abs(this.railH - this.containerH);

      window.addEventListener('resize', e => {
        this._setSize();
      });

      this._setSize();
    },

    _double() {
      if ( window.innerWidth <= 768 ) return;

      [].forEach.call(this.items, (item, key) => {
        if ( key < 2 ) {
          const clone = item.cloneNode(true);
          item.parentElement.appendChild(clone);
        }
      });
    },

    _setSize() {
      [].forEach.call(this.items, item => {
        const img = item.querySelector('img');
        img.style.height = item.offsetWidth * 1.4 + 'px';
      });
    },

    _setupHorizontal() {
      if ( window.innerWidth >= 768 ) return;

      let railW = 0;

      [].forEach.call(this.rails, rail => {
        const items = rail.querySelectorAll('.js-item');

        let w = 0;

        [].forEach.call(items, item => {
          w += item.offsetWidth;
        });

        if ( w > railW ) railW = w;
      });

      this.rail.style.width = railW + 'px';
    },

    _moving() {
      this.rail.style.transform = "translate(0px, 0px)";
      let start = 0;

      this._setupHorizontal();

      // Double
      // this._double();

      const action = () => {
        let direction = 'y';
        let track = this.diff;

        const w = window.innerWidth;

        if (w < 768) {
          direction = 'x';
          track = Math.abs(this.container.offsetWidth - this.rail.offsetWidth);
        }

        if (start >= track) {
          start = 0;
        }

        start += 1;

        if (direction === 'y') {
          this.rail.style.transform = `translate(0px, -${start}px)`;
        }
        if (direction === 'x') {
          this.rail.style.transform = `translate(-${start}px, 0px)`;
        }
      };

      let timer = setInterval(action, (24 * 2));

      window.addEventListener('resize', () => {
        clearInterval(timer);
        this.rail.style.transform = "translate(0px, 0px)";
        start = 0;
        timer = setInterval(action, (24 * 2));
      });
    },
  };

  instapics.init();
})();
