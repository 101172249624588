(() => {
  const form = {
    BOUNCEROPTIONS: {
      messageAfterField: true,
      messages: {
        missingValue: {
          checkbox: 'Обязательное поле',
          radio: 'Выберите значение',
          select: 'Выберите значение',
          'select-multiple': 'Выберите значение',
          default: 'Обязательное поле'
        },
        patternMismatch: {
          email: 'Не верный формат e-mail',
          default: 'Проверьте формат значения'
        },
        phoneNum: 'Введите верный номер'
      },
      disableSubmit: true
    },

    init() {
      this._formValidation();
      this._formSending();
      this._fgLabeled();
    },

    _formValidation() {
      if (document.querySelector('[data-bouncer]')) {
        const bouncer = new Bouncer('[data-bouncer]', this.BOUNCEROPTIONS);
      }
    },

    _formSending() {
      document.addEventListener('bouncerFormValid', e => {
        const form = e.target;
        form.classList.add('is-process');
        let status = true;

        // Demo Search
        if (form.id === 'h-search-form') {
          document.querySelector('#h-search-result').innerHTML = '';

          setTimeout(() => {
            this._fillSearch(form);
          }, 1000);
          return;
        }
        // .end Demo Search

        // Send form demo
        setTimeout(() => {
          form.classList.remove('is-process');

          // Footer Subscribe Demo
          if (form.id === 'footerSubscribe') {
            let email = document.forms[form.name].email.value;

            if (email === 'false@mail.com') {
              status = false;
            }
          }
          // .end Footer Subscribe Demo

          if (status === true) {
            form.classList.add('is-sent');
          } else {
            form.classList.add('is-fail');
          }
        }, 2000);

        // Show Message
        setTimeout(() => {
          if (status === true) {
            form.reset();
          }
          form.classList.remove('is-sent');
          form.classList.remove('is-fail');
        }, 4000);
        // .end Send form demo

      }, false);
    },

    _fgLabeled() {
      if (!document.querySelector('.js-fg-labeled')) return;

      const elems = document.querySelectorAll('.js-fg-labeled');

      const markInput = (e, elem) => {
        if (e.currentTarget.value !== '') {
          elem.classList.add('is-focus');
        } else {
          elem.classList.remove('is-focus');
        }
      };

      [].forEach.call(elems, elem => {
        const input = elem.querySelector('.js-input');

        input.addEventListener('change', (e) => {
          markInput(e, elem);
        });

        input.addEventListener('blur', (e) => {
          markInput(e, elem);
        });
      });
    },

    _fillSearch(form) {
      const searchHtml = axios.get('/search-result.html')
        .then(res => {
          if (!res.data) {
            // Ничего не найдено и т.п.
            form.classList.remove('is-process');
          } else {
            const div = document.createElement('div');
            div.innerHTML = res.data;
            const html = div.querySelector('#search-result').innerHTML;

            const target = document.querySelector('#h-search-result');
            target.innerHTML = html;

            const searchSlider = new Swiper('#h-search-slider', {
              slidesPerView: 'auto'
            });

            form.classList.remove('is-process');
          }
        })
        .catch(err => {
          console.error(err);
        });
    }
  };

  form.init();
})();
