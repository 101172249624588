(() => {
  const kurs = {
    init() {
      if (!document.querySelector("#kurs-form")) return;

      this.form = document.querySelector("#kurs-form");
      this.personPrice = parseInt(this.form.querySelector('[name="person-price"]').value);

      this._inputCounter('#kurs-form-counter-persons');
      this._inputCounter('#kurs-form-counter-days');
      this._dateSelect();

      this._calcPrice(1);
    },

    _inputCounter(elemID) {
      if (!document.querySelector(elemID)) return;

      const targetID = document.querySelector(elemID).dataset.elem;

      const setValue = (el, value) => {
        const target = document.getElementById(el.dataset.elem);
        const text = target.dataset.text.split(',');

        let textIn = '';

        if (value == 1) {
          textIn = text[0];
        }

        if (value > 1 && value < 5) {
          textIn = text[1];
        }

        if (value > 4) {
          textIn = text[2];
        }

        target.innerText = `${value} ${textIn}`;
      };

      const inumber = new INumber.default(elemID, {
        init: instance => {
          setValue(instance.el, instance.formatValue);
        },
        change: (value, formatValue, el, id) => {
          setValue(el, formatValue);

          if ( id == '#kurs-form-counter-persons' ) {
            this._calcPrice(value);
          }
        }
      });
    },

    _calcPrice( value ) {
      const price = value * this.personPrice;

      document.getElementById('kurs-form-price').innerHTML = price;
      this.form.querySelector('[name="price"]').value = price;
    },

    _dateSelect() {
      if (!document.querySelector("#kurs-form-input-date")) return;

      const form = document.querySelector("#kurs-form");
      const items = form.querySelectorAll('.js-date');

      const setValue = (item) => {
        const form = document.querySelector("#kurs-form");
        const input = form.querySelector("#kurs-form-input-date");
        const elem = form.querySelector("#kurs-form-date");

        const text = item.innerHTML;
        const value = item.dataset.date;

        input.value = value;
        elem.innerText = text;
      };

      const activateItem = (elems, elem) => {
        [].forEach.call(elems, item => item.classList.remove('is-active'));
        elem.classList.add('is-active');
      };

      setValue(items[0]);
      activateItem(items, items[0]);

      [].forEach.call(items, item => {
        item.addEventListener('click', e => {
          setValue(e.currentTarget);
          activateItem(items, e.currentTarget);
        });
      });
    }
  };

  kurs.init();
})();
