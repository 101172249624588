(() => {
  const video = {
    modalOptions: {
      openTrigger: 'data-modal-open',
      closeTrigger: 'data-modal-close',
      onShow: modal => {
        video._closeModal(modal);

        if ( video.youtube_status ) {
          video.player.stopVideo();
        }
      },
      onClose: modal => {

        if ( video.youtube_status ) {
          video.youtube_status = false;
          video.player.stopVideo();
        }
      }
    },

    init() {
      if ( !document.querySelector('.js-video-button') ) return;

      this._setup();
      this._createYT();
      this._action();
    },

    _setup() {
      this.buttons = document.querySelectorAll('.js-video-button');
      this.youtube = document.querySelector('#modal-youtube-iframe');
      this.youtube_status = false;
    },

    _createYT() {
      YT.ready(() => {
        video.player = new YT.Player('modal-youtube-iframe', {
          videoId: '',
          playerVars: {
            // controls: 0,
            showinfo: 0,
            rel: 0,
            modestbranding: 0,
            iv_load_policy: 3,
            fs: 0,
            cc_load_policy: 0,
          }
        });
      });
    },

    _action() {
      document.addEventListener('click', e => {
        if ( !e.target.closest('.js-video-button') ) return;

        const btn = e.target.closest('.js-video-button');

        const type = btn.dataset.type;
        let file = btn.dataset.video;

        if ( type == 'file' ) {
          this.video_status = true;
          this.video.src = file;
          MicroModal.show('video-file', this.modalOptions);
        }

        if ( type == 'youtube' ) {
          this.youtube_status = true;
          this.player.loadVideoById(file);
          MicroModal.show('modal-youtube', this.modalOptions);
        }
      });
    },

    _closeModal( modal ) {
      modal.addEventListener('click', e => {
        if ( !e.target.closest('[data-modal-close-btn]') ) return;

        MicroModal.close(modal.id);
      });
    }
  };

  video.init();
})();
