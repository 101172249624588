(() => {
  const buildRipples = () => {
    if (!document.querySelector('#bubbles')) return;

    const obj = $('#bubbles');
    const sw = window.innerWidth;

    if (sw < 1200) return;

    let pic = '';

    if (sw >= 1300) {
      pic = '/assets/images/placeholder/bg-hero-2x.jpg';
    } else {
      pic = '/assets/images/placeholder/bg-hero-1x.jpg';
    }

    obj.ripples({
      imageUrl: pic,
      dropRadius: 35,
      perturbance: .0055,
      interactive: false,
    });

    setTimeout(() => {
      obj.ripples('drop', (window.innerWidth / 2) + (window.innerWidth / 4), window.innerHeight / 2, 30, .2);
      obj.ripples('set', 'interactive', true);
    }, 500);

    window.addEventListener('resize', () => {
      obj.ripples('updateSize');
    });
  };

  buildRipples();
})();
