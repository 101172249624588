(() => {
  const toggler = {
    init() {
      const toggler = new Unitoggle.default({
        onOpen: tab => {
          const group = ( tab.hasAttribute('data-group') ) ? tab.dataset.group : '';

          if (group === 'header') {
            this._headerModal(tab, 'open');
          }

          if (group === 'yacht-spec') {
            const sectionYachtSpec = document.querySelector('#section-yacht-spec-wrapper');
            document.querySelector('#section-yacht-spec').classList.add('is-active');
            const winW = window.innerWidth;
            zenscroll.to(sectionYachtSpec);

            document.documentElement.classList.add('is-no-scroll');

            const body = tab.querySelector('.half-modal__body');

            if ( body ) {
              body.scrollTop = 0;
            }
          }

          if ( group == 'tabs-pictures' ) {
            this._tabsPicturesSlider(tab, tab.dataset.index);
          }
        },
        onClose: tab => {
          const group = ( tab.hasAttribute('data-group') ) ? tab.dataset.group : '';

          if (group === 'header') {
            this._headerModal(tab, 'close');
          }

          if (tab.id === 'header-form') {
            tab.querySelector('form').reset();
          }

          if (group === 'yacht-spec') {
            document.querySelector('#section-yacht-spec').classList.remove('is-active');
            document.documentElement.classList.remove('is-no-scroll');
          }
        }
      });
    },

    _headerModal(tab, action) {
      const header = document.querySelector('#header');
      const headerCloseButton = document.querySelector('#header-close-button');

      // Добавляем класс к header
      const headerModify = (className) => {
        if (!header.classList.contains(className)) {
          header.classList.add(className);
        } else {
          header.classList.remove(className);
        }
      };

      this._disableScroll();

      // Открываем
      if (action === 'open') {
        // Задем объект для кнопки закрытия
        headerCloseButton.dataset.toggle = tab.id;

        if (!headerCloseButton.classList.contains('is-active')) {
          headerCloseButton.classList.add('is-active');
        }

        // Стиль, если меню
        if (tab.id === 'header-menu') {
          headerModify('is-active-light');
          return;
        }
      }

      // Закрываем
      if (action === 'close') {
        headerCloseButton.dataset.toggle = 'header-menu';

        if (tab.id === 'header-menu') {
          headerModify('is-active-light');
          return;
        }

        // Поиск
        if (tab.id === 'header-search') {
          tab.querySelector('form').reset();
          tab.querySelector('#h-search-result').innerHTML = '';
        }
      }

      headerModify('is-active-dark');
    },

    _disableScroll() {
      const body = document.body;
      const className = 'is-disabled-scroll';

      if (!body.classList.contains(className)) {
        body.classList.add(className);
      } else {
        body.classList.remove(className);
      }
    },

    _tabsPicturesSlider(tab, index) {
      // console.log(tab, index);

      const container = document.querySelector('#tabs-box');
      const pics = container.querySelectorAll('.js-pic');

      let current = 0;

      [].forEach.call( pics, (pic, index) => {
        pic.classList.remove('is-current');

        if ( pic.classList.contains('is-active') ) {
          pic.classList.add('is-current');
        }

        pic.classList.remove('is-active');
      } );

      gsap.set( pics[index], { x: '-100%' } );
      pics[index].classList.add('is-active');

      gsap.to( pics[index], { x: 0, duration: 2.5, ease: 'expo.out' } );
    }
  };

  toggler.init();
})();
