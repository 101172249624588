(() => {
  const leftPicAnimation = {
    init() {
      if ( !document.querySelector('#pic-left-box') ) return;

      if ( window.innerWidth < 1024 ) return;

      this.container = document.querySelector('#pic-left-box');
      this.items = this.container.querySelectorAll('.js-box');
      this.pics = this.container.querySelectorAll('.js-pic-box');
      this.picElems = this.container.querySelectorAll('.js-pic');

      const sidebar = new StickySidebar('#pic-left-box-sidebar', {
        containerSelector: '#pic-left-box',
        innerWrapperSelector: '#pic-left-box-sidebar-inner',
        topSpacing: 0,
        bottomSpacing: 0
      });

      this._setup();
    },

    _setup() {
      this.current = 0;
      this.prev = 0;
      this.buffer = 0;
      this.zIndex = 0;

      this.isAnimated = false;
      this.animStack = [];

      let index = this.pics.length * 2;

      [].forEach.call(this.pics, elem => {
        elem.style.zIndex = index;
        index -= 1;
      });

      this._activate();

      this.zIndex = this.pics.length * 2 + 1;

      let arr = [];

      [].forEach.call(this.items, (elem, index) => {
        elem.addEventListener('mouseenter', e => {
          this.current = index;
          this._inactiveAll();
        });
      });
    },

    _inactiveAll() {
      [].forEach.call(this.items, (elem, index) => {
        if ( elem.classList.contains('is-active') ) {
          this.prev = index;
        }
      });

      if ( this.prev == this.current ) return;

      [].forEach.call(this.items, (elem, index) => {
        elem.classList.remove('is-active');
      });

      [].forEach.call(this.picElems, elem => {
        gsap.set(elem, { width: '100%', scale: 1, duration: 0 });
        gsap.set(elem, { opacity: 1, duration: 0 });
        elem.classList.remove('is-active');
      });

      gsap.set(this.picElems[this.current], { width: 0, scale: 1.15, opacity: 1, duration: 0 });

      this.pics[this.current].style.zIndex = this.zIndex + 2;
      this.pics[this.prev].style.zIndex = this.zIndex + 1;

      this.zIndex += 2;

      this._activate();
    },

    _activate() {
      const animation = 'CustomEase.create("custom", "M0,0 C0,0 0.05519,0.00316 0.08747,0.00957 0.11151,0.01435 0.12768,0.0196 0.14983,0.02975 0.1743,0.04097 0.19075,0.0503 0.21214,0.0667 0.23737,0.08606 0.25654,0.09997 0.27439,0.1253 0.31274,0.17969 0.33754,0.22048 0.36575,0.28455 0.43925,0.45146 0.4659,0.57054 0.54055,0.72973 0.56598,0.78394 0.59677,0.81627 0.6363,0.86149 0.65918,0.88768 0.6794,0.90251 0.70861,0.92203 0.73791,0.94161 0.75962,0.95299 0.79244,0.96582 0.82594,0.97891 0.84977,0.9853 0.88557,0.99119 0.92814,0.9982 1,1 1,1 ")';

      this.items[this.current].classList.add('is-active');
      this.picElems[this.current].classList.add('is-active');

      gsap.to(this.picElems[this.current], {
        opacity: 1,
        width: '100%',
        scale: 1,
        ease: animation,
        duration: 2
      });
    }
  };

  leftPicAnimation.init();
})();
